
img {
  border-radius: 50%;
  height: 35px;
  margin-top: -10px;
  border: 0px solid rgb(139, 139, 139);
}
.video-js .vjs-control.vjs-logo-control {
  /* This will be updated when cdn catches up */
  min-width: 0;
}
.video-js .vjs-control-bar, 
.video-js .vjs-big-play-button, 
.video-js .vjs-modal-dialog {
  background-color: #1f3089;
}

.center {
  margin-left: 60px;
}
.App {
  text-align: center;
  margin: 0 auto;
}
.video-js {
  align-items: center;
  margin-left: 0px;
  align-content: center;
  z-index: 55;
  width: auto;
  height: 90%;
}

.fullscreen{
  z-index: 999;
  margin-top: -25px;
  background: black;
  margin-left: -24px;
  width: 70%;
  position: fixed;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.banner{
  border-radius: 0%;
  background: black;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  height: 101px;
  flex-direction: column;
}
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  overflow-wrap: break-word;
  
  
}
.tex {
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin-top: -4px;
}
.tex p{
  margin-bottom: 0;
  margin-top: -5px;
  padding: 2px;
  max-width: 200px;
}
.tex .name{ 
  color: #000;
}
.msg {
  display: flex;
  padding: 20px 5px 0 20px;
  margin: 7px;
  padding-bottom: 50px;
  border-radius: 3000px;
  align-items: center;
}

.sent {
  background-color: #1f3089;
  color: white;
  border-top-right-radius: 1000px;
  flex-direction: row-reverse;
  padding: 10px 10px 0 10px;
  margin-right: -35px;
  text-align: right;
  float: right;
}

.received {
  border: 1px solid lightgray;
  background-color: #FAFAFA;
  color: black;
  padding: 10px 10px 0 10px;
  margin-left: 38px;
  border-top-left-radius: 1000px;
  float: left;
  text-align: left;
}

.sendMsg {
  position: fixed;
  display: flex;
  width: 32%;
  bottom: 0;
  z-index: 1;
  padding: 10px;
  padding-left: 45px;
  padding-bottom: 30px;
  background-color: #1f3089;
}

.msgs {
  z-index: 1;
  width: 100%;
  margin-top: 0;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 960px) {
  .video-js {
    align-items: center;
    margin-left: 0px;
    align-content: center;
    z-index: 55;
    width: auto;
    height: 80%;
  }
  p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    overflow-wrap: break-word;
  }
  .msg {
    display: flex;
    padding: 20px 5px 0 20px;
    margin: 7px;
    padding-bottom: 50px;
    border-radius: 3000px;
    align-items: center;
  }
  .sent {
    color: white;
    border-top-right-radius: 1000px;
    flex-direction: row-reverse;
    padding: 10px 10px 0 10px;
    margin-right: -35px;
    text-align: end;
    float: right;
  }
  .received {
    background-color: #FAFAFA;
    padding: 10px 10px 0 10px;
    margin-left: 0px;
    border-top-left-radius: 1000px;
    float: left;
  }
  img {
    height: 35px;
    margin-top: -10px;
  }
  .fullscreen{
    order: 1;
    z-index: 999;
    margin-top: -25px;
    padding-top: 0px;
    width: 50%;
    height: 100%;
    background-color: black;
    box-shadow: 0 0 20px rgb(164, 164, 164);

  } 
 
  .sendMsg {
    position: fixed;
    display: flex;
    width: 50%;
    bottom: 0;
    z-index: 1;
    padding: 10px;
    margin-left: -20px;
    padding-bottom: 30px;
    background-color: #1f3089;
  }
  .msgs {
    z-index: 1;
    width: 100%;
    margin-top: 0;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
  }
  
}


@media (max-width: 775px) {
  img {
    border-radius: 50%;
    height: 35px;
    margin-top: -10px;
    border: 2px solid rgb(139, 139, 139);
  }
  .container {
    
  }
  .video-js {
    align-items: center;
    margin-left: 0px;
    align-content: center;
    z-index: 55;
    width: auto;
    height: 80%;
  }
  
  .fullscreen{
    z-index: 999;
    margin-top: -25px;
    padding-top: 55px;
    background: black;
    margin-left: -24px;
    width: 50%;
    position: fixed;
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    overflow-wrap: break-word;
  }
  
  .msg {
    display: flex;
    padding: 20px 5px 0 20px;
    margin: 7px;
    padding-bottom: 50px;
    border-radius: 3000px;

    align-items: center;
  }
  
  .sent {
    color: white;
    border-top-right-radius: 0px;
    flex-direction: row-reverse;
    padding: 10px 10px 0 10px;
    margin-right: -35px;
    text-align: end;
    float: right;
  }
  
  .received {
    border: 1px solid lightgray;
    background-color: #FAFAFA;
    padding: 10px 10px 0 10px;
    margin-left: 15px;
    border-top-left-radius: 0px;
    float: left;
  }
  

  .sendMsg {
  position: fixed;
  display: flex;
  width: 50%;
  bottom: 0;
  z-index: 1;
  padding: 10px;
  padding-left: 45px;
  padding-bottom: 30px;
  background-color: #1f3089;
}
  .msgs {
    z-index: 1;
    width: 100%;
    margin-top: 0;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
  }
    img {
  border-radius: 50%;
  height: 35px;
  margin-top: -10px;
  border: 0px solid rgb(139, 139, 139);
}
}

@media (max-width: 600px) {
  .fullscreen{
    z-index: 999;
    margin-top: -25px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    height: 24%;
    box-shadow: 0 0 15px rgb(109, 124, 211);

  }
  p {
    margin-top: 8px;
    font-size: 15px;
  }
  .sent {
    margin-right: -25px;
    padding: 7px 7px 0 7px;
  }
  .received {
    margin-left: -25px;
    padding: 7px 7px 0 7px;
  }
  img {
    border: 0px solid rgb(139, 139, 139);
    height: 25px;
    margin-top: -7px;
  }
  .vjs-logo-content > img{
    position:absolute;
}
  .sendMsg {
    margin-left: -40px;
    width: 100%;
  }
  .msgs {
    
    margin: 63px 0;
  }
  .video-js {
    background: black;
    width: 100%;
    height: 100%;
    background-color: black;
  }
  .tex p{
    margin-bottom: 0;
    margin-top: -5px;
    padding: 2px;
    max-width: 250px;
  }
  .center {
    margin-left: 0px;
  }
}


